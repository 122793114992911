<template>
    <div>
        <block v-if="loading" />
        <v-card flat class="pa-4 bar">
            <div class="d-flex justify-end align-center">
                <v-btn
                    small
                    class="btn-add mr-1"
                    @click="printconfig"
                >
                    <v-icon>
                        mdi-printer
                    </v-icon>
                </v-btn>
                <v-btn
                    small
                    class="btnsave mr-1"
                    @click="getReportSales"
                >
                    <v-icon>
                        mdi-magnify
                    </v-icon>
                   
                </v-btn>
            </div>
        </v-card>
        <v-row class="mt-2">
            <v-col cols="12" md="6">
                <v-select
                    v-model="search.month"
                    :items="monthList"
                    item-text="monthName"
                    item-value="monthIndex"
                    label="Mes"
                    outlined
                    attach
                    dense
                    @change="getReportSales"
                ></v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select
                    v-model="search.year"
                    :items="yearLIst"
                    item-text="text"
                    item-value="value"
                    label="Año"
                    outlined
                    attach
                    dense
                    @change="getMonths"                   
                ></v-select>
            </v-col>
        </v-row>
        <GeneralFilter
        :filterEndpoint="$API.accountingreports.getFilterPurchasesByDate" :search="search"
        @emptyFilter="getReportSales" @filtered="filterHandler">
            <DxDataGrid 
            :dataSource="DataTable"
            :columns="columns"
            @exporting="onExporting"
            >
                <DxExport :enabled="true" />
                <DxPager :showPageSizeSelector="true" allowedPageSizes="[5, 10, 20]" />
                <DxPaging defaultPageSize="5" />
            </DxDataGrid>
        </GeneralFilter>
        <alerts
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
        ></alerts>
        <template v-for="win in windowsList">
             <component :is="win.component" :key="win.key" v-bind="win.props" />
        </template>

    </div>
</template>

<script>
    
    //import {mapState} from 'vuex';
    import {DxDataGrid, DxPager, DxPaging, DxExport} from 'devextreme-vue/data-grid';
    import GeneralFilter from '../../../components/GeneralFilter.vue';
    import Alerts from '@/components/Alerts.vue';
    import Block from '@/components/Block.vue';
    import { printToExcel } from "@/helpers/printToexcel";
    import SubPrint from '../SubPrint.vue';

    export default {
        name: "PurchaseReport",
        components: {
            DxDataGrid,
            DxPager,
            DxPaging,
            Block,
            Alerts,
            GeneralFilter,
            DxExport,
            SubPrint
        },
        props: ['win'],
        data() {
            return {
                print: {
                    show: false,
                    header: {
                        month: null,
                        year: null,
                        reportType: null,
                    },
                    data: [],
                },
                windowsList: [],
                DataTable: [],
                panel: 0,
                tab: null,
                key: 0,
                loading: false,
                search: {
                    month: null,
                    year: null
                },
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                monthList: [
                    {text: 'Enero', value: 1},
                    {text: 'Febrero', value: 2},
                    {text: 'Marzo', value: 3},
                    {text: 'Abril', value: 4},
                    {text: 'Mayo', value: 5},
                    {text: 'Junio', value: 6},
                    {text: 'Julio', value: 7},
                    {text: 'Agosto', value: 8},
                    {text: 'Septiembre', value: 9},
                    {text: 'Octubre', value: 10},
                    {text: 'Noviembre', value: 11},
                    {text: 'Diciembre', value: 12},
                ],
                yearLIst: [
                    {text: '2020', value: 2020},
                    {text: '2021', value: 2021},
                    {text: '2022', value: 2022},
                    {text: '2023', value: 2023},
                ],
                columns: [
                    { dataField: "FECHA DE EMISIÓN", caption: "FECHA DE EMISIÓN" },
                    { dataField: "CLASE DE DOCUMENTO", caption: "CLASE DE DOCUMENTO" },
                    { dataField: "TIPO DE DOCUMENTO", caption: "TIPO DE DOCUMENTO" },
                    { dataField: "NÚMERO DE DOCUMENTO", caption: "NÚMERO DE DOCUMENTO" },
                    { dataField: "NIT O NRC DEL PROVEEDOR", caption: "NIT O NRC DEL PROVEEDOR" },
                    { dataField: "NOMBRE DEL PROVEEDOR", caption: "NOMBRE DEL PROVEEDOR" },
                    { dataField: "COMPRAS INTERNAS EXENTAS Y/O NO SUJETAS", caption: "COMPRAS INTERNAS EXENTAS Y/O NO SUJETAS" },
                    { dataField: "INTERNACIONES EXENTAS Y/O NO SUJETAS", caption: "INTERNACIONES EXENTAS Y/O NO SUJETAS" },
                    { dataField: "IMPORTACIONES EXENTAS Y/O NO SUJETAS", caption: "IMPORTACIONES EXENTAS Y/O NO SUJETAS" },
                    { dataField: "COMPRAS INTERNAS GRAVADAS", caption: "COMPRAS INTERNAS GRAVADAS" },
                    { dataField: "INTERNACIONES GRAVADAS DE BIENES", caption: "INTERNACIONES GRAVADAS DE BIENES" },
                    { dataField: "IMPORTACIONES GRAVADAS DE BIENES", caption: "IMPORTACIONES GRAVADAS DE BIENES" },
                    { dataField: "CRÉDITO FISCAL", caption: "CRÉDITO FISCAL" },
                    { dataField: "TOTAL DE COMPRAS", caption: "TOTAL DE COMPRAS" },
                    { dataField: "DUI DEL PROVEEDOR", caption: "DUI DEL PROVEEDOR" },
                    { dataField: "TIPO DE OPERACIÓN", caption: "TIPO DE OPERACIÓN" },
                    { dataField: "CLASIFICACIÓN", caption: "CLASIFICACIÓN" },
                    { dataField: "SECTOR", caption: "SECTOR" },
                    { dataField: "TIPO DE COSTO / GASTO", caption: "TIPO DE COSTO / GASTO" },
                    { dataField: "NÚMERO DE ANEXO", caption: "NÚMERO DE ANEXO" },
                ]
            }
        },
        computed:{
            
        },
        async mounted() {
            this.yearLIst = ( await this.$API.accountingreports.getPurchaseOrderDateYears() ).years;
            this.search.year = this.yearLIst[ this.yearLIst.length - 1 ].value;
            this.monthList = ( await this.$API.accountingreports.getPurchaseOrderDateMonths( this.search ) ).months
            this.search.month = this.monthList[ this.monthList.length - 1 ].monthIndex;
        },
        methods: {
            async getMonths(){
                this.monthList = ( await this.$API.accountingreports.getPurchaseOrderDateMonths( this.search ) ).months
                 this.search.month = this.monthList[ this.monthList.length - 1 ].monthIndex;
            },
            printconfig(){
                
                this.print.show = false;
                const month = this.monthList.find( (element) => element.monthIndex == this.search.month );
                console.log(month);
                this.print.header.month = month.monthName;
                this.print.header.year = this.search.year;
                
                this.print.data.body = this.DataTable;
                console.log(this.print.data.body);
                //this.reloadPrint++;
                this.print.show = true;

                this.windowsList.push({
                    component: SubPrint,
                    props: {
                        print: this.print,
                        IsPurchase: true              
                    },
                    key: new Date().getTime(),
                });          
           },
            filterHandler(data) {
                this.DataTable = data.data.data;
                this.DataTable.forEach((element, index) => {
                      element.index = index + 1;
                    });
            },
           getReportSales(){
            if(this.search.month == null || this.search.year == null){
                    
                    return;
            }
           
            this.loading = true;
            this.$API.accountingreports.getPurchasesByDate(this.search).then((res) => {
               
                this.DataTable = res.data.data;

                this.DataTable.forEach((element, index) => {
                      element.index = index + 1;
                    });
                this.loading = false;
            }).catch((err) => {
                this.loading = false;
                this.showAlert("danger", "Error", err.response.data.message);
            });
           },
           closeAlert() {
                this.alert.show = false;
            },
            acceptAlert() {
                this.alert.show = false;

            
            },
            onExporting(e) {
                const array = [ "No Sujeta", "ISLR", "IVA", "Retencion", "Sub total ", "Total" ];
              printToExcel(e, "Reporte de Compras", array);
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
        },

    }
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}


</style>