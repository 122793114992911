import { render, staticRenderFns } from "./PurchaseReport.vue?vue&type=template&id=6f50f7fe&scoped=true&"
import script from "./PurchaseReport.vue?vue&type=script&lang=js&"
export * from "./PurchaseReport.vue?vue&type=script&lang=js&"
import style0 from "./PurchaseReport.vue?vue&type=style&index=0&id=6f50f7fe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f50f7fe",
  null
  
)

export default component.exports