<template>
    <div>
        <block v-if="loading" />
        <v-card flat class="pa-4 bar">
            <div class="d-flex justify-end align-center">
                <v-btn small class="btn-add mr-1" @click="printconfig">
                    <v-icon>
                        mdi-printer
                    </v-icon>
                </v-btn>
                <v-btn small class="btnsave mr-1" @click="getReportSales">
                    <v-icon>
                        mdi-magnify
                    </v-icon>

                </v-btn>
            </div>
        </v-card>
        <v-row class="mt-4">
            <v-col cols="12" md="6">
                <v-select v-model="search.month" :items="monthList" item-text="MES" item-value="monthIndex" label="Mes"
                    outlined attach dense @change="getReportSales"></v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select v-model="search.year" :items="yearLIst" item-text="AÑO" item-value="AÑO" label="Año" outlined
                    attach dense @change="getmonths"></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-radio-group v-model="reportType" row>

                    <v-radio label="Contribuyentes" value="1"></v-radio>
                    <v-radio label="Consumidores Finales" value="2"></v-radio>

                </v-radio-group>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-radio-group v-model="dataType">
                    <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
                    <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>

        <GeneralFilter v-if="reportType === '1'" :filterEndpoint="$API.accountingreports.getFilterSalesByDate"
            :search="{ ...search, reportType, dataType }" @emptyFilter="getReportSales" @filtered="filterHandler">
            <DxDataGrid v-if="reportType === '1'" :dataSource="DataTable" :columns="columnsTaxPayer" @exporting="onExporting"
                :word-wrap-enabled="true" :column-auto-width="true" :scrolling="{ useNative: true }">
                <DxExport :enabled="true" />
                <DxPager :showPageSizeSelector="true" allowedPageSizes="[5, 10, 20]" />
                <DxPaging defaultPageSize="5" />
            </DxDataGrid>
        </GeneralFilter>
        <DxDataGrid v-if="reportType === '2'" :dataSource="DataTable2" :columns="columnsFinalCustomer" @exporting="onExporting"
            :word-wrap-enabled="true" :column-auto-width="true" :scrolling="{ useNative: true }">
            <DxExport :enabled="true" />
            <DxPager :showPageSizeSelector="true" allowedPageSizes="[5, 10, 20]" />
            <DxPaging defaultPageSize="5" />
        </DxDataGrid>
        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
        <component v-for="win in windowsList" :is="win.component" :key="win.key" v-bind="win.props" />
        <!-- <template >
        </template> -->


    </div>
</template>

<script>

//import {mapState} from 'vuex';
import { DxDataGrid, DxPager, DxPaging, DxExport } from 'devextreme-vue/data-grid';
import GeneralFilter from '../../../components/GeneralFilter.vue';
import Alerts from '@/components/Alerts.vue';
import Block from '@/components/Block.vue';
import { printToExcel } from "@/helpers/printToexcel";

import SubPrint from '../SubPrint.vue';

export default {
    name: "SalesReport",
    components: {
        DxDataGrid,
        DxPager,
        DxPaging,
        SubPrint,
        Block,
        Alerts,
        GeneralFilter,
        //  PrintReportsSales,
        DxExport
    },
    props: ['win'],
    watch: {
        reportType(newVal) {
            this.search.reportType = newVal;
        },
        dataType(newVal) {
            this.search.dataType = newVal;
        },
    },
    data() {
        return {
            windowsList: [

            ],
            print: {
                show: false,
                header: {
                    month: null,
                    year: null,
                    reportType: null,
                },
                data: [],
            },
            dataType: '01',
            reportType: '1',
            showPrint: false,
            reloadPrint: 0,
            DataTable: [],
            DataTable2: [],
            panel: 0,
            tab: null,
            key: 0,
            loading: false,
            search: {
                month: null,
                year: null,
                reportType: '1'
            },
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            monthList: [
                { text: 'Enero', value: 1 },
                { text: 'Febrero', value: 2 },
                { text: 'Marzo', value: 3 },
                { text: 'Abril', value: 4 },
                { text: 'Mayo', value: 5 },
                { text: 'Junio', value: 6 },
                { text: 'Julio', value: 7 },
                { text: 'Agosto', value: 8 },
                { text: 'Septiembre', value: 9 },
                { text: 'Octubre', value: 10 },
                { text: 'Noviembre', value: 11 },
                { text: 'Diciembre', value: 12 },
            ],
            yearLIst: [
                { text: '2020', value: 2020 },
                { text: '2021', value: 2021 },
                { text: '2022', value: 2022 },
                { text: '2023', value: 2023 },
            ],
            columnsTaxPayer: [
                { dataField: "FECHA EMISIÓN DEL DOCUMENTO", caption: "FECHA EMISIÓN DEL DOCUMENTO" },
                { dataField: "CLASE DE DOCUMENTO", caption: "CLASE DE DOCUMENTO" },
                { dataField: "TIPO DE DOCUMENTO", caption: "TIPO DE DOCUMENTO" },
                { dataField: "NÚMERO DE RESOLUCIÓN", caption: "NÚMERO DE RESOLUCIÓN" },
                { dataField: "NUMERO DE SERIE DE DOCUMENTO", caption: "NUMERO DE SERIE DE DOCUMENTO" },
                { dataField: "NÚMERO DE DOCUMENTO", caption: "NÚMERO DE DOCUMENTO" },
                { dataField: "NÚMERO DE CONTROL INTERNO", caption: "NÚMERO DE CONTROL INTERNO" },
                { dataField: "NIT O NRC DEL CLIENTE", caption: "NIT O NRC DEL CLIENTE" },
                { dataField: "NOMBRE, RAZÓN SOCIAL O DENOMINACIÓN", caption: "NOMBRE, RAZÓN SOCIAL O DENOMINACIÓN" },
                { dataField: "VentasExentasFormatted", caption: "VENTAS EXENTAS" },
                { dataField: "VentasNoSujetasFormatted", caption: "VENTAS NO SUJETAS" },
                { dataField: "DebitoFiscalFormatted", caption: "DÉBITO FISCAL" },
                { dataField: "VentasCuentaDeTercerosNoDomiciliadosFormatted", caption: "VENTAS A CUENTA DE TERCEROS NO DOMICILIADOS" },
                { dataField: "DebitoFiscalVentaTercerosFormatted", caption: "DÉBITO FISCAL POR VENTA A CUENTA DE TERCEROS" },
                { dataField: "TotalVentasFormatted", caption: "TOTAL VENTAS" },
            ],
            columnsFinalCustomer: [
                { dataField: "InvoiceDate", caption: "FECHA DE EMISIÓN" },
                { dataField: "ClaseDeDocumento", caption: "CLASE DE DOCUMENTO" },
                { dataField: "TipoDeDocumento", caption: "TIPO DE DOCUMENTO" },
                { dataField: "NumeroDeResolucion", caption: "NÚMERO DE RESOLUCIÓN" },
                { dataField: "SerieDeDocumento", caption: "SERIE DEL DOCUMENTO" },
                { dataField: "NumeroDeControlInternoDel", caption: "NÚMERO DE CONTROL INTERNO (DEL)" },
                { dataField: "NumeroDeControlInternoDelAl", caption: "NÚMERO DE CONTROL INTERNO (AL)" },
                { dataField: "NumeroDeDocumentoDel", caption: "NÚMERO DE DOCUMENTO (DEL)" },
                { dataField: "NumeroDeDocumentoAl", caption: "NÚMERO DE DOCUMENTO (AL)" },
                { dataField: "NoDeMaquinaRegistradora", caption: "NÚMERO DE MÁQUINA REGISTRADORA" },
                { dataField: "VentasExentas", caption: "VENTAS EXENTAS" },
                { dataField: "VentasInternasExentasNoSujetasAProporcionalidad", caption: "VENTAS INTERNAS EXENTAS NO SUJETAS A PROPORCIONALIDAD" },
                { dataField: "VentasNoSujetas", caption: "VENTAS NO SUJETAS" },
                { dataField: "VentasGravadasLocales", caption: "VENTAS GRAVADAS LOCALES" },
                { dataField: "ExportacionesDentroDelAreaCentroamericana", caption: "EXPORTACIONES DENTRO DEL ÁREA CENTROAMERICANA" },
                { dataField: "ExportacionesFueraDelAreaCentroamericana", caption: "EXPORTACIONES FUERA DEL ÁREA CENTROAMERICANA" },
                { dataField: "ExportacionesDeServicios", caption: "EXPORTACIONES DE SERVICIOS" },
                { dataField: "VentasAZonasFrancasYDPATasaCero", caption: "VENTAS A ZONAS FRANCAS Y DPA (TASA CERO)" },
                { dataField: "VentaACuentaDeTercerosNoDomiciliados", caption: "VENTAS A CUENTA DE TERCEROS NO DOMICILIADOS" },
                { dataField: "TotalVentas", caption: "TOTAL VENTAS" },
                { dataField: "NumeroDeAnexo", caption: "NÚMERO DE ANEXO" },
            ]
        }
    },
    computed: {

    },
    async mounted() {
        this.yearLIst = (await this.$API.accountingreports.getSalesDateYears()).years;
        this.search.year = this.yearLIst[this.yearLIst.length - 1].AÑO;
        this.monthList = (await this.$API.accountingreports.getSalesDateMonths(this.search)).months
        this.search.month = this.monthList[this.monthList.length - 1].monthIndex;
    },
    methods: {
        async getmonths() {
            this.monthList = (await this.$API.accountingreports.getSalesDateMonths(this.search)).months
            this.search.month = this.monthList[this.monthList.length - 1].monthIndex;
        },
        filterHandler(data) {
            if (this.reportType === '1') {
                this.DataTable = data.data;
            } else {
                this.DataTable2 = data.data;
            }
        },
        getReportSales() {
            if (this.search.month == null || this.search.year == null) {
                return;
            }

            this.loading = true;
            this.$API.accountingreports.getSalesByDate({ ...this.search, reportType: this.reportType, dataType: this.dataType }).then((res) => {
                if (this.reportType === '1') {
                    this.DataTable = res.data;
                } else {
                    console.log(res)
                    this.DataTable2 = res.data;
                }
                this.loading = false;
            }).catch((err) => {
                this.loading = false;
                this.showAlert("danger", "Error", err);
            });
        },
        printconfig() {
            this.showPrint = true;
            this.print.show = false;
            const month = this.monthList.find((element) => element.monthIndex == this.search.month);

            this.print.header.month = month.MES;
            this.print.header.year = this.search.year;
            this.print.header.reportType = this.reportType;
            this.print.data.body = this.reportType === '1' ? this.DataTable : this.DataTable2;
            console.log(this.print.data.body);
            //this.reloadPrint++;
            this.print.show = true;

            this.windowsList.push({
                component: SubPrint,
                props: {
                    print: this.print,
                },
                key: new Date().getTime(),
            });

        },
        closeAlert() {
            this.alert.show = false;
        },
        acceptAlert() {
            this.alert.show = false;


        },
        onExporting(e) {
            let array = null;
            if (this.reportType === '1') {
                array = ['Exportaciones', 'Ventas Gravadas', 'Débito Fiscal', 'Venta a Cuenta de Terceros', 'Débito Fiscal a Cuenta de Terceros',
                    'No Sujeta', 'Exenta', 'Retencion', 'Impuesto Percibido', 'Ventas Totales'
                ];
            } else {
                array = ['Ventas Exentas', 'Ventas Gravadas', 'Exportaciones', 'Venta Total'];
            }
            console.log(array);
            printToExcel(e, "Reporte de ventas", array);
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
    },

}
</script>

<style scoped>
.bar {
    width: 100%;
    background-color: rgb(60, 60, 60) !important;
    color: white !important;
    border-style: solid;
    border-color: black;
    border-radius: 15px !important;
    margin-top: 1rem !important;
}

.btn-add {
    background-color: #3498DB !important;
    color: white;
}

.btnsave {
    background-color: #00A178 !important;
    color: white;
}

.btnclean {
    background-color: #F29D35 !important;
    color: white;
}

.btndelete {
    background-color: #E3191E !important;
    color: white;
}
</style>